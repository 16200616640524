<template>
  <v-app>
    <v-app-bar app color="white" dark :elevation="$vuetify.breakpoint.smAndDown?1:0" >
       <v-spacer></v-spacer>
      <div class="d-flex align-center " v-if="$vuetify.breakpoint.smAndDown">
        <v-img 
          alt="Vuetify Logo"
          class="shrink mr-2"
           min-width="10rem"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="100"
        />
      </div>
       <div class="d-flex align-center mt-8" >
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="15rem"
          src="./assets/logo.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
