import Vue from 'vue'
import Vuex from 'vuex';
import axios from 'axios';

const base_url = "https://hq.newhomes.ng/api";
const axiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
    "Accept": "application/json"
  }
};
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    developers:[],
    developer_properties:[],
    all_units:[]
  },
  mutations: {
    ALL_DEVELOPER(state,payload){
      state.developers = payload;
    },
    DEVELOPER_PROPERTIES(state,payload){
      state.developer_properties=payload;
    },
    ALL_UNIT(state,payload){
      state.all_units=payload;
    }
  },
  actions: {
    async fetchAllDeveloper({commit}) {
      let res = await axios.get(base_url+"/general/house-developers");
      let d = res.data.data;
      this.commit("ALL_DEVELOPER",d);
    },
    async fetchAllDeveloperProperties({commit},id) {
      let res = await axios.get(base_url + "/general/developer-properties/"+id);
      let d = res.data.data;
      this.commit("DEVELOPER_PROPERTIES", d);
    },
    async fetchAllPropertiesUnit({commit},id) {
      let res = await axios.get(base_url + "/general/property-units/" + id);
      let d = res.data.data;
      this.commit("ALL_UNIT", d);
    },
    async saveInfo({commit},data) {
      let res = await axios.post(base_url + "/general/house-owner-affordability-test", data);
      let d = res.data.data;
      return d;
    },
  },
  modules: {
  }
})
