<template>
  <div class="offtaker_form">
    
          <v-container>
            <v-row align-center justify="center" fill-height>
            <v-col cols="12" sm="12" md="8">
                <v-card class="elevation-0" flat>
                  <h2 class="text-center my-8">Off Taker Profiler</h2>
                  <v-form ref="form">
                      <v-row>
                      <v-col cols="12" md="6">
                        <v-select :rules="[rules.required]"
                          :items="developers"
                          item-text="developer_name"
                          item-value="id"
                          v-model="form.developer_id"
                          outlined
                          label="Developer"
                          @change="getproperties"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                          <v-select v-model="form.property_id" :rules="[rules.required]"
                          :items="properties"
                           item-text="property_name"
                          item-value="id"
                          outlined
                          label="Development"
                          @change="getpropertiesunits"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                          <v-select v-model="form.unit_id" :rules="[rules.required]"
                          :items="units"
                          item-text="unit_bedrooms"
                          item-value="id"
                          outlined
                          label="Property Bedrooms Subscribe To"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field type="date" v-model="form.dob" label="Date of birth" outlined :rules="[rules.required]"/>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field type="text" v-model="form.name" label="Full Name" outlined :rules="[rules.required]"/>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field type="text" v-model="purchase_price" label="Purchase price (₦)" outlined />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field type="text" v-model="equity_price" label="Equity paid (₦)" outlined />
                      </v-col>
                      <v-col cols="12" md="6">
                          <v-select :rules="[rules.required]" v-model="form.is_ras_holder"
                           :items="yesNoOption"
                          item-text="text"
                          item-value="value"
                          outlined
                          label="RSA holder"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                          <v-select :rules="[rules.required]" v-model="form.employment_status"
                          :items="['Self Employed','Employed','Unemployed']"
                          outlined
                          label="Employment Status"
                        ></v-select>
                      </v-col>
                       <v-col cols="12" md="6">
                        <v-text-field type="text" label="Employer" outlined v-model="form.employer" />
                      </v-col>
                       <v-col cols="12" md="6">
                        <v-text-field type="text" v-model="monthly_income" label="Monthly Income (₦)" outlined :rules="[rules.required]"/>
                      </v-col>
                      <v-col cols="12" md="6">
                          <v-select :rules="[rules.required]" v-model="form.borrower_type"
                          :items="['Joint','Single']"
                          outlined
                          label="Borrower type"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                          <v-select v-model="form.mode_of_payment"
                          :items="['Direct Deduction','Transfer or Cash Payment']"
                          outlined
                          label="Form of Payment"
                        ></v-select>
                      </v-col>
                        <v-col cols="12" md="6">
                          <v-select :rules="[rules.required]"
                          :items="yesNoOption"
                          item-text="text"
                          item-value="value"
                          outlined
                          label="Do you have mortgage"
                        ></v-select>
                      </v-col>
                            <v-col cols="12" md="6">
                          <v-select :rules="[rules.required]"
                           :items="yesNoOption" v-model="form.interested_in_mortgage"
                          item-text="text"
                          item-value="value"
                          outlined
                          label="Interested in Mortgage"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row align-center justify="center" fill-height>
                      <v-col cols="12" sm="12" md="6">
                        <v-btn :loading="loading" :disabled="loading" color="primary" block @click.prevent="save">Submit</v-btn>
                      </v-col>
                  </v-row>
                </v-card>
            </v-col>
              
            
        </v-row>
          </v-container>
      
  </div>
</template>

<script>

  export default {
    name: "Home",
    data(){
      return{
        loading:false,
        yesNoOption: [
          {
          text: 'Yes',
          value: 1
          },
          {
          text: 'No',
          value: '0'
          }
        ],
           rules: {
              required: value => !!value || "Required.",
              counter: value => value.length <= 20 || "Max 20 characters",
              email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail address.";
              }
            },
        form:{
          developer_id:"", 
          property_id:"",
          unit_id:"",  
          name:"",
          dob:"",
          is_ras_holder:"",
          employment_status:"",
          employer:"",
          subscribed_property:"",
          mode_of_payment:"",
          interested_in_mortgage:"",
          borrower_type:"",
          purchase_price:"",
          monthly_income:"",
          joint_borrower_monthly_income:"",
          loanable_amount:"",
          down_payment:"",
          monthly_repayment:"",
          loan_tenure:"",
          loan_rate:""
        }
      }
    },
    computed:{
      developers(){
        let t = ['Alpha Mead','Brain and Hammers','Mixta','Urban Shelter'];
        let developers = this.$store.state.developers;
        return developers;
      },
      properties(){
        let developers = this.$store.state.developer_properties;
        return developers;
      },
      units(){
        let developers = this.$store.state.all_units;
        return developers;
      },
      purchase_price:{
        get(){
          return this.currencyFormat(this.form.purchase_price);
        },
        set(v){
          this.form.purchase_price = this.removeCommaFromNumber(v);
        }
      },
      equity_price:{
        get(){
          return this.currencyFormat(this.form.down_payment);
        },
        set(v){
          this.form.down_payment = this.removeCommaFromNumber(v);
        }
      },
      monthly_income:{
        get(){
          return this.currencyFormat(this.form.monthly_income);
        },
        set(v){
          this.form.monthly_income = this.removeCommaFromNumber(v);
        }
      },
    },
    methods:{
        removeCommaFromNumber(v) {
            return parseInt(v.replace(/,/g, ""));
        },
        remove_white_pace(str){
            return str.replace(/\s/g, '');
        },
        currencyFormat(value) {
            const formatted = Number(value).toLocaleString("en");
        if (formatted === '0') return value;
        else if (formatted === 'NaN') return '';
        return formatted;
        },

        getproperties(){
          let id = this.form.developer_id;
          this.$store.dispatch("fetchAllDeveloperProperties",id);
        },
        getpropertiesunits(){
          let id = this.form.property_id;
          this.$store.dispatch("fetchAllPropertiesUnit",id);
        },
        save(){
            if (this.$refs.form.validate()) {
              console.log(this.form);
              this.loading=true
                this.$store.dispatch("saveInfo",this.form).then((res)=>{
                  this.loading=false
                  this.clearForm();
                  this.$swal('Information Save Successfully !')
                }).catch((e)=>{
                  this.loading=false;
                  console.log(e.response.data);
              });
            }
            return ;
          
        },
        clearForm(){
          this.    form={
          developer_id:"", 
          property_id:"",
          unit_id:"",  
          name:"",
          dob:"",
          is_ras_holder:"",
          employment_status:"",
          employer:"",
          subscribed_property:"",
          mode_of_payment:"",
          interested_in_mortgage:"",
          borrower_type:"",
          purchase_price:"",
          monthly_income:"",
          joint_borrower_monthly_income:"",
          loanable_amount:"",
          down_payment:"",
          monthly_repayment:"",
          loan_tenure:"",
          loan_rate:""
        }
        }
    },
    created(){
      this.$store.dispatch("fetchAllDeveloper");
    }
  }
</script>
<style lang="scss" scoped>
  .offtaker_form{

  }
</style>
